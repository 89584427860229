import { forEach } from './util';

// Can be called with element or with number
export const selected = current => {
  const image = document.querySelector('.product-order__image');
  const price = document.querySelector('.product-order__price');
  const dollar = document.querySelector('.product-order__dollar');
  const name = document.querySelector('.product-order__subtitle');
  const button = document.querySelector('.product-order__button');
  const buttonText = button.querySelector('span');
  const stock = current.getAttribute('data-stock');
  const buttonLabel = current.getAttribute('data-label');
  image.setAttribute('src', current.getAttribute('data-image'));
  price.textContent = `${current.getAttribute('data-price')}`;
  dollar.textContent = `${current.getAttribute('data-dollar')}`;
  name.textContent = `${current.getAttribute('data-name')}`;
  if (stock === 'outofstock') {
    button.setAttribute('disabled', 'disabled');
    button.classList.add('button--disabled');
  } else {
    button.removeAttribute('disabled');
    button.classList.remove('button--disabled');
  }
  buttonText.innerText = buttonLabel;
};

export const enhancer = (el) => {
  const options = document.querySelector('.product-order__input');
  options[0].click();
  selected(options[0]);
  forEach(options, (option) => {
    option.addEventListener('click', (e) => {
      selected(option);
    });
  });
};
